import React, { useEffect, Fragment } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import { listProducts } from "../../../actions/productActions";

const useStyles = makeStyles(styles);

export default function SectionProducts(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { error, loading, products } = productList;
  const category = props.category;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {loading ? (
          <h2>Зареждане</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <GridContainer justify="center">
            {products.map((product) => (
              <Fragment key={product._id}>
                {category == product.category ? (
                  <GridItem key={product._id} xs={12} sm={6} md={3}>
                    <Link
                      to={"/product-details/" + product._id}
                      className={classes.link}
                    >
                      <img
                        src={product.image}
                        alt="..."
                        width={250}
                        height={250}
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </Link>
                    <h3 className={classes.title}>{product.name}</h3>
                    <p
                      className={classes.text}
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {product.price} лв.
                    </p>
                    {/*
                      <p
                        className={classes.text}
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {product.description}
                      </p>
                    */}
                    <Button
                      color="white"
                      size="sm"
                      round
                      component={Link}
                      to={"/product-details/" + product._id}
                    >
                      Поръчай
                    </Button>
                    <div>
                      <br></br>
                    </div>
                  </GridItem>
                ) : (
                  <p></p>
                )}
              </Fragment>
            ))}
          </GridContainer>
        )}
      </div>
    </div>
  );
}

SectionProducts.propTypes = {
  category: PropTypes.string.isRequired,
};
