import { container } from "assets/jss/material-kit-react.js";
import { containerFluid } from "assets/jss/material-kit-react.js";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const componentsStyle = {
  container,
  ...imagesStyle,
  brand: {
    color: "#3C4858",
    textAlign: "left",
    marginLeft: "-6em",
    //marginTop: "5em",
  },
  title: {
    //marginBottom: "3em",
    fontSize: "3.2rem",
    //fontWeight: "600",
    display: "inline-block",
    position: "relative",
  },
  titleLeft: {
    fontSize: "1.56rem",
    textAlign: "left",
  },
  textLeft: {
    fontSize: "1.2rem",
    textAlign: "left",
  },
  messageTrue: {
    padding: "3px",
    backgroundColor: "green",
    border: "1px solid grey",
    borderRadius: "3px",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  },
  messageFalse: {
    padding: "3px",
    backgroundColor: "#f3f781",
    border: "1px solid grey",
    borderRadius: "3px",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
  },
  subtitle: {
    fontSize: "1.313rem",
    //maxWidth: "500px",
    margin: "10px 0 0",
    marginTop: "10px",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainDetail: {
    position: "relative",
    margin: "100px 0 0 0",
    padding: "20px 0 40px 40px",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-80px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  link: {
    textDecoration: "none",
    color: "#3c4858",
    margin: "12px",
  },
  textCenter: {
    textAlign: "center",
  },
  section: {
    padding: "100px 0",
  },
  container1: {
    ...containerFluid,
    textAlign: "center !important",
  },
  button: {
    whiteSpace: "normal",
    float: "left",
  },
  titleDetail: {
    fontSize: "1.2rem",
    fontWeight: "400",
    position: "relative",
    color: "#3c4858",
    display: "inline-block",
    float: "left",
  },
  cardBody: {},
};

export default componentsStyle;
