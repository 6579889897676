import React, { useEffect, useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link } from "react-router-dom";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { createOrder } from "../../actions/orderActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { ORDER_CREATE_RESET } from "../../constants/orderConstants";
import HorizontalNonLinearStepper from "components/HorizontalNonLinearStepper/HorizontalNonLinearStepper.js";
import { logout } from "../../actions/userActions";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles1 = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  mainDetail2: {
    [theme.breakpoints.down("xs")]: {
      width: "min-content",
    },
  },
}));

function PlaceOrderPage(props) {
  const classes = useStyles1();
  const classes2 = useStyles2();

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, error: errorOrderCreate, success } = orderCreate;
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  cart.itemsPrice = cart.cartItems.reduce(
    (acc, item) => acc + item.price * item.qty,
    0
  );
  //cart.shippingPrice = (cart.itemsPrice < 100 ? 0 : 10).toFixed(2);
  const { cartItems, error, loading } = cart;
  if (Object.values(cart.shippingAddress)[0] == "пл.Възраждане 8 ") {
    cart.totalPrice = Number(cart.itemsPrice);
    cart.shippingPrice = 0;
  } else {
    cart.shippingPrice = (cart.itemsPrice < 100 ? 0 : 10).toFixed(2);
    cart.totalPrice = Number(cart.itemsPrice) + Number(cart.shippingPrice);
  }

  // if (typeof cart.shippingAddress.shippingDate === "object") {
  //   cart.shippingAddress.shippingDate =
  //     cart.shippingAddress.shippingDate.toLocaleDateString();
  // }

  if (!cart.paymentMethod) {
    props.history.push("/payment");
  }

  useEffect(() => {
    if (success) {
      props.history.push(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
    if (errorOrderCreate) {
      setMessage("Моля, логни се отново");
      dispatch(logout());
      props.history.push("/login-page");
    }
  }, [success, props.history, errorOrderCreate]);

  const checkoutHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        invoiceData: cart.invoiceData,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <div>
      <Header
        brand="Fresh & Cheese"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div
        className={classNames(
          classes.main,
          classes.mainDetail,
          classes2.mainDetail2
        )}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button component={Link} to="/" className={classes.button}>
              Назад
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.dividerBottom}>
              <HorizontalNonLinearStepper location={props.history} />
            </div>
          </GridItem>
        </GridContainer>
        <div className={classes.section}>
          <div className={classes.container1}>
            {loading ? (
              <h2>Зареждане</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <h3 className={classes.titleLeft}>Информация за доставка:</h3>
                  <p className={classes.textLeft}>
                    {cart.shippingAddress.address}, {cart.shippingAddress.city},{" "}
                    {cart.shippingAddress.postalCode}
                  </p>
                  <p className={classes.textLeft}>
                    {"Телефон:"} {cart.shippingAddress.phoneNumber}
                  </p>
                  <h3 className={classes.titleLeft}>Начин на плащане:</h3>
                  {cart.paymentMethod == "Transfer on bank account" ? (
                    <div>
                      <p className={classes.textLeft}>Превод по банков път</p>
                      <p className={classes.textLeft}>
                        Банкова Сметка: BG57RZBB91551013337870; Райфайзен Банк;
                      </p>
                    </div>
                  ) : (
                    <p className={classes.textLeft}>Наложен платеж</p>
                  )}
                  <h3 className={classes.titleLeft}>Данни за фактура:</h3>
                  {Object.keys(cart.invoiceData).length !== 0 ? (
                    <p className={classes.textLeft}>
                      {cart.invoiceData.companyName}, {cart.invoiceData.eik},{" "}
                      {cart.invoiceData.ddsNumber}, {cart.invoiceData.mol},{" "}
                      {cart.invoiceData.invoiceCity},{" "}
                      {cart.invoiceData.invoiceAddress}
                    </p>
                  ) : (
                    <p className={classes.textLeft}>Без фактура</p>
                  )}
                  <h3 className={classes.titleLeft}>Ден за получаване:</h3>
                  <p className={classes.textLeft}>
                    {typeof cart.shippingAddress.shippingDate == "object"
                      ? cart.shippingAddress.shippingDate
                          .toLocaleString()
                          .substring(0, 10)
                      : cart.shippingAddress.shippingDate.substring(0, 10)}
                  </p>
                  <h3 className={classes.titleLeft}>Коментари:</h3>
                  <p className={classes.textLeft}>
                    {cart.shippingAddress.comment}
                  </p>
                  <h3 className={classes.titleLeft}>Поръчани продукти:</h3>
                  {cartItems == 0 ? (
                    <h3>Количката е празна.</h3>
                  ) : (
                    <Table>
                      <TableBody>
                        {cartItems.map((item) => (
                          <TableRow key={item.product}>
                            <TableCell>
                              <img src={item.image} width="60" height="60" />
                            </TableCell>
                            <TableCell>
                              <Link to={`/product-details/${item.product}`}>
                                {item.name}
                              </Link>
                            </TableCell>
                            <TableCell>{item.price} лв.</TableCell>
                            <TableCell>
                              {item.qty} X {item.price} лв. ={" "}
                              {(item.qty * item.price).toFixed(2)} лв.
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h3 className={classes.titleLeft}>
                    Общо ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                    продукти
                  </h3>
                  <Table style={{ borderWidth: "1px" }}>
                    <TableBody>
                      {Object.values(cart.shippingAddress)[0] ==
                      "пл.Възраждане 8" ? (
                        <TableRow>
                          <TableCell>
                            Цена доставка: {cart.shippingPrice} лв.
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow></TableRow>
                      )}

                      <TableRow>
                        <TableCell>
                          Общо:{" "}
                          {cartItems
                            .reduce(
                              (acc, item) => acc + item.qty * item.price,
                              0
                            )
                            .toFixed(2)}{" "}
                          лв.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Button
                            className={classes.button}
                            fullWidth
                            disabled={cartItems.length === 0}
                            onClick={checkoutHandler}
                          >
                            Завърши поръчката{" "}
                            <i
                              style={{ marginLeft: "1em" }}
                              className="fas fa-shopping-bag"
                            ></i>
                          </Button>
                          {message && (
                            <p className={classes.divider}>{message}</p>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

PlaceOrderPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default PlaceOrderPage;
