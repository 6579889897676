import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonIcon from "@material-ui/icons/Person";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
//import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import profile from "assets/img/faces/profile.jpg";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUserProfile } from "../../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import { listMyOrders } from "../../actions/orderActions";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    if (!userInfo) {
      props.history.push("/login-page");
    } else {
      if (!user || !user.name || success || userInfo.id !== user.id) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        dispatch(listMyOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, history, userInfo, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password != confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name: name,
          email: email,
          password: password,
        })
      );
      setMessage("");
    }
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  return (
    <div>
      <Header
        color="transparent"
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>{name}</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "User Profile",
                      tabIcon: PersonIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.container}>
                              {loading ? (
                                <h2>Loading</h2>
                              ) : error ? (
                                <h2>{error}</h2>
                              ) : (
                                <GridContainer justify="center">
                                  <GridItem xs={12} sm={12} md={12}>
                                    <Card className={classes[cardAnimaton]}>
                                      <form
                                        onSubmit={submitHandler}
                                        className={classes.form}
                                      >
                                        <CardHeader
                                          color="primary"
                                          className={classes.cardHeader}
                                        >
                                          <h4>Update your profile data</h4>
                                          {message && <p>{message}</p>}
                                        </CardHeader>
                                        <CardBody>
                                          <CustomInput
                                            labelText="Name..."
                                            id="name"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              required: true,
                                              type: "name",
                                              inputRef: { name },
                                              value: name,
                                              onChange: (e) =>
                                                setName(e.target.value),
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Icon
                                                    className={
                                                      classes.inputIconsColor
                                                    }
                                                  >
                                                    person_outline
                                                  </Icon>
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                          <CustomInput
                                            labelText="Email..."
                                            id="email"
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              required: true,
                                              type: "email",
                                              inputRef: { email },
                                              value: email,
                                              onChange: (e) =>
                                                setEmail(e.target.value),
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Email
                                                    className={
                                                      classes.inputIconsColor
                                                    }
                                                  />
                                                </InputAdornment>
                                              ),
                                            }}
                                          />
                                          <CustomInput
                                            labelText="Password"
                                            id="pass"
                                            value={password}
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "password",
                                              inputRef: { password },
                                              onChange: (e) =>
                                                setPassword(e.target.value),
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Icon
                                                    className={
                                                      classes.inputIconsColor
                                                    }
                                                  >
                                                    lock_outline
                                                  </Icon>
                                                </InputAdornment>
                                              ),
                                              autoComplete: "off",
                                            }}
                                          />
                                          <CustomInput
                                            labelText="Confirm Password"
                                            id="passConfirm"
                                            value={password}
                                            onChange={(e) =>
                                              setPassword(e.target.value)
                                            }
                                            formControlProps={{
                                              fullWidth: true,
                                            }}
                                            inputProps={{
                                              type: "password",
                                              inputRef: { confirmPassword },
                                              onChange: (e) =>
                                                setConfirmPassword(
                                                  e.target.value
                                                ),
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <Icon
                                                    className={
                                                      classes.inputIconsColor
                                                    }
                                                  >
                                                    lock_outline
                                                  </Icon>
                                                </InputAdornment>
                                              ),
                                              autoComplete: "off",
                                            }}
                                          />
                                        </CardBody>
                                        <CardFooter
                                          className={classes.cardFooter}
                                        >
                                          <Button type="onsubmit" size="lg">
                                            Update
                                          </Button>
                                        </CardFooter>
                                      </form>
                                    </Card>
                                  </GridItem>
                                </GridContainer>
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                    {
                      tabButton: "My Orders",
                      tabIcon: ShoppingBasketIcon,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.container}>
                              {loadingOrders ? (
                                <h2>Loading</h2>
                              ) : errorOrders ? (
                                <h2>{errorOrders}</h2>
                              ) : (
                                <GridContainer justify="center">
                                  <GridItem xs={12} sm={12} md={12}>
                                    <Card className={classes[cardAnimaton]}>
                                      <CardHeader
                                        color="primary"
                                        className={classes.cardHeader}
                                      >
                                        <h4>My ordered items</h4>
                                      </CardHeader>
                                      <CardBody>
                                        <Table>
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>
                                                <strong>ID</strong>
                                              </TableCell>
                                              <TableCell>
                                                <strong>Date</strong>
                                              </TableCell>
                                              <TableCell>
                                                <strong>Total</strong>
                                              </TableCell>
                                              <TableCell>
                                                <strong>Paid</strong>
                                              </TableCell>
                                              <TableCell>
                                                <strong>Delivered</strong>
                                              </TableCell>
                                            </TableRow>
                                            {orders.map((order) => (
                                              <TableRow key={order._id}>
                                                <TableCell>
                                                  {order._id}
                                                </TableCell>
                                                <TableCell>
                                                  {order.createdAt.substring(
                                                    0,
                                                    10
                                                  )}
                                                </TableCell>
                                                <TableCell>
                                                  $ {order.totalPrice}
                                                </TableCell>
                                                <TableCell>
                                                  {order.isPaid
                                                    ? order.paidAt
                                                    : "Not paid"}
                                                </TableCell>
                                                <TableCell>
                                                  <Button
                                                    component={Link}
                                                    to={"/order/" + order._id}
                                                    className={classes.button}
                                                  >
                                                    Details
                                                  </Button>
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </CardBody>
                                      <CardFooter
                                        className={classes.cardFooter}
                                      ></CardFooter>
                                    </Card>
                                  </GridItem>
                                </GridContainer>
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                      ),
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

ProfilePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
