import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardFooter from "components/Card/CardFooter.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
//import CustomInput from "components/CustomInput/CustomInput.js";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { listOrders } from "../../actions/orderActions";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function OrderListPage(props) {
  const classes = useStyles();
  //const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  const dispatch = useDispatch();

  const orderList = useSelector((state) => state.orderList);
  const { error, loading, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      props.history.push("/login-page");
    }
  }, [dispatch, history, userInfo]);

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div className={classNames(classes.main, classes.mainDetail)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button component={Link} to="/" className={classes.button}>
              Go Back
            </Button>
          </GridItem>
        </GridContainer>
        <div className={classes.section}>
          <div className={classes.container1} style={{ overflowX: "auto" }}>
            {loading ? (
              <h2>Loading</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <h3>Orders</h3>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>ID</strong>
                        </TableCell>
                        <TableCell>
                          <strong>User</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Date</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Total</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Paid</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Delivered</strong>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {orders.map((order) => (
                        <TableRow key={order._id}>
                          <TableCell>{order._id}</TableCell>
                          <TableCell>{order.user && order.user.name}</TableCell>
                          <TableCell>
                            {order.createdAt.substring(0, 10)}
                          </TableCell>
                          <TableCell>{order.totalPrice}</TableCell>
                          <TableCell>
                            {order.isPaid ? (
                              order.paidAt.substring(0, 10)
                            ) : (
                              <i
                                className="fas fa-times"
                                style={{ color: "red" }}
                              ></i>
                            )}
                          </TableCell>
                          <TableCell>
                            {order.isDelivered ? (
                              order.deliveredAt.substring(0, 10)
                            ) : (
                              <i
                                className="fas fa-times"
                                style={{ color: "red" }}
                              ></i>
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              component={Link}
                              to={"/order/" + order._id}
                              className={classes.button}
                            >
                              Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

OrderListPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
