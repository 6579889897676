import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const steps = ["Профил", "Доставка", "Плащане", "Поръчка"];

export default function HorizontalNonLinearStepper(props) {
  const location = props.location.location.pathname;
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    if (step == 0) {
      window.location.replace("/#/login-page");
    } else if (step == 1) {
      window.location.replace("/#/shipping");
    } else if (step == 2) {
      window.location.replace("/#/payment");
    } else {
      window.location.replace("/#/placeorder");
    }
  };

  const handleReset = () => {
    // setActiveStep(0);
    // setCompleted({});
  };

  useEffect(() => {
    if (!userInfo) {
      window.location.replace("/#/login-page");
    } else {
      if (location == "/shipping") {
        setActiveStep(1);
        setCompleted({ 0: true });
      } else if (location == "/payment") {
        setActiveStep(2);
        setCompleted({ 0: true, 1: true });
      } else if (location == "/placeorder") {
        setActiveStep(3);
        setCompleted({ 0: true, 1: true, 2: true });
      }
    }
  }, [activeStep]);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Активира стъпка {activeStep + 1}
              </Typography>
            </React.Fragment>
          )}
        </div>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}

HorizontalNonLinearStepper.propTypes = {
  location: PropTypes.shape({
    location: PropTypes.object.isRequired,
  }).isRequired,
};
