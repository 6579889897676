import React, { useEffect } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link } from "react-router-dom";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { getOrderDetails, deliverOrder } from "../../actions/orderActions";
import { ORDER_DELIVER_RESET } from "../../constants/orderConstants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles1 = makeStyles(styles);

function OrderPage(props) {
  const classes = useStyles1();

  const orderId = props.match.params.id;

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: successDeliver, loading: loadingDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  if (!loading && !error) {
    order.itemsPrice = order.orderItems.reduce(
      (acc, item) => acc + item.price * item.qty,
      0
    );
  }

  useEffect(() => {
    if (!userInfo) {
      props.history.push("/login-page");
    }
    if (!order || order._id !== Number(orderId) || successDeliver) {
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, order, orderId, successDeliver]);

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  const addition = (value1, value2) => {
    return Number(value1) + Number(value2);
  };

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div className={classNames(classes.main, classes.mainDetail)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button component={Link} to="/" className={classes.button}>
              Назад
            </Button>
          </GridItem>
        </GridContainer>
        <div className={classes.section}>
          <div className={classes.container1}>
            {loading ? (
              <h2>Зареждане</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <h3 className={classes.titleLeft}>
                    Номер на поръчката: {order._id}
                  </h3>
                  <h3 className={classes.titleLeft}>
                    Информация за доставката:
                  </h3>
                  <p className={classes.textLeft}>Име: {order.user.name}</p>
                  <p className={classes.textLeft}>Имейл: {order.user.email}</p>
                  <p className={classes.textLeft}>
                    Адрес: {order.shippingAddress.address},{" "}
                    {order.shippingAddress.city},{" "}
                    {order.shippingAddress.postalCode}
                  </p>
                  <p className={classes.textLeft}>
                    Телефон: {order.shippingAddress.phoneNumber}
                  </p>
                  {order.isDelivered ? (
                    <p className={classes.textLeft}>
                      <mark className={classes.messageFalse}>
                        Доставено на {order.deliveredAt.substring(0, 10)}
                      </mark>
                    </p>
                  ) : (
                    <p className={classes.textLeft}>
                      <mark className={classes.messageFalse}>
                        Чака се доставка
                      </mark>
                    </p>
                  )}
                  <h3 className={classes.titleLeft}>Начин на плащане:</h3>
                  <p className={classes.textLeft}>{order.paymentMethod}</p>
                  <h3 className={classes.titleLeft}>Ден на получаване:</h3>
                  <p className={classes.textLeft}>
                    {order.shippingAddress.shippingDate.substring(0, 10)}
                  </p>
                  <h3 className={classes.titleLeft}>Данни за фактура:</h3>
                  <p className={classes.textLeft}>
                    {order.invoiceData.companyName}, {order.invoiceData.eik},{" "}
                    {order.invoiceData.ddsNumber}, {order.invoiceData.mol},{" "}
                    {order.invoiceData.invoiceCity},{" "}
                    {order.invoiceData.invoiceAddress}
                  </p>
                  {order.isPaid ? (
                    <p className={classes.textLeft}>
                      <mark className={classes.messageFalse}>
                        Платено на {order.paidAt}
                      </mark>
                    </p>
                  ) : (
                    <p className={classes.textLeft}>
                      <mark className={classes.messageFalse}>
                        Чака се плащане
                      </mark>
                    </p>
                  )}
                  <h3 className={classes.titleLeft}>Поръчани продукти:</h3>
                  {order.orderItems == 0 ? (
                    <h3>Няма поръчки.</h3>
                  ) : (
                    <Table>
                      <TableBody>
                        {order.orderItems.map((item) => (
                          <TableRow key={item.product}>
                            <TableCell>
                              <img src={item.image} width="60" height="60" />
                            </TableCell>
                            <TableCell>
                              <Link to={`/product-details/${item.product}`}>
                                {item.name}
                              </Link>
                            </TableCell>
                            <TableCell>$ {item.price}</TableCell>
                            <TableCell>
                              {item.qty} X {item.price} лв. ={" "}
                              {(item.qty * item.price).toFixed(2)} лв.
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h3 className={classes.titleLeft}>
                    Общо (
                    {order.orderItems.reduce((acc, item) => acc + item.qty, 0)})
                    продукти
                  </h3>
                  <Table style={{ borderWidth: "1px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          Цена доставка: {order.shippingPrice} лв.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Обща стойност:{" "}
                          {addition(order.itemsPrice, order.shippingPrice)}
                          лв.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {loadingDeliver && <h2>Loading</h2>}
                  {userInfo && userInfo.isAdmin && !order.isDelivered && (
                    <GridItem>
                      <Button
                        onClick={deliverHandler}
                        className={classes.button}
                      >
                        Маркирай за доставено
                      </Button>
                    </GridItem>
                  )}
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

OrderPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderPage;
