import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { listProductDetails } from "../../actions/productActions";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function ProductPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [qty, setQty] = useState(1);
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const cheeseCakeSizes = [8, 10, 12, 14];
  const designCakesSizes = [12, 14];

  const [cheeseCakePieseQty, setCheeseCakePieseQty] = useState(
    cheeseCakeSizes[0]
  );

  const [designCakePieseQty, setDesignCakePieseQty] = useState(
    designCakesSizes[0]
  );

  useEffect(() => {
    dispatch(listProductDetails(props.match.params.id));
  }, [dispatch, props.match]);

  const addToCartHandler = () => {
    //props.history.push(`/cart/${props.match.params.id}?qty=${qty}`);
    props.history.push({
      pathname: `/cart/${props.match.params.id}?qty=${qty}`,
      state: {
        data: "ff",
      },
    });
  };

  //console.log(props.history);

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        image="assets/img/cakes.jpeg"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainDetail)}>
        <Button component={Link} to="/" className={classes.button}>
          Назад
        </Button>
        <div className={classes.section}>
          <div className={classes.container1}>
            {loading ? (
              <h2>Зареждане</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <div>
                    <img
                      src={product.image}
                      alt="..."
                      className={
                        classes.imgRaised +
                        " " +
                        classes.imgRounded +
                        " " +
                        classes.imgFluid
                      }
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Card>
                    <CardHeader>
                      <h3 className={classes.titleDetail}>{product.name}</h3>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <p>{product.description}</p>
                      <h3 className={classes.titleDetail}>
                        {product.price} лв.
                      </h3>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Card>
                    <CardHeader>
                      <h3 className={classes.titleDetail}>
                        Цена: {product.price} лв.
                      </h3>
                    </CardHeader>
                    <CardBody className={classes.cardBody}>
                      <div>
                        <h3 className={classes.titleDetail}>
                          Статус:
                          {product.countInStock > 0
                            ? "Налично"
                            : "Няма наличност"}
                        </h3>
                        {product.countInStock > 0 && (
                          <FormControl
                            style={{ minWidth: 100, float: "left " }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Количество
                            </InputLabel>
                            <Select
                              label="Количество"
                              value={qty}
                              onChange={(e) => setQty(e.target.value)}
                            >
                              {[...Array(product.countInStock).keys()].map(
                                (x) => (
                                  <MenuItem key={x + 1} value={x + 1}>
                                    {x + 1}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        )}
                        {product.countInStock > 0 &&
                        product.category == "cheesecakes" ? (
                          <FormControl
                            style={{ minWidth: 100, float: "left " }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Парчета
                            </InputLabel>
                            <Select
                              label="Парчета"
                              value={cheeseCakePieseQty}
                              onChange={(e) =>
                                setCheeseCakePieseQty(e.target.value)
                              }
                            >
                              {cheeseCakeSizes.map((x) => (
                                <MenuItem key={x} value={x}>
                                  {x}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : product.category == "designcakes" ? (
                          <FormControl
                            style={{ minWidth: 100, float: "left " }}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Парчета
                            </InputLabel>
                            <Select
                              label="Парчета"
                              value={designCakePieseQty}
                              onChange={(e) =>
                                setDesignCakePieseQty(e.target.value)
                              }
                            >
                              {designCakesSizes.map((x) => (
                                <MenuItem key={x} value={x}>
                                  {x}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <FormControl></FormControl>
                        )}
                      </div>
                    </CardBody>
                    <CardBody className={classes.cardBody}>
                      <Button
                        onClick={addToCartHandler}
                        disabled={product.countInStock == 0}
                        className={classes.button}
                      >
                        Добави в количката
                      </Button>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

ProductPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }).isRequired,
};
