import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  listProducts,
  deleteProduct,
  createProduct,
} from "../../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../../constants/productConstants";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function ProductListPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { error, loading, products } = productList;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    error: errorCreate,
    loading: loadingCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    error: errorDelete,
    loading: loadingDelete,
    success: successDelete,
  } = productDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });
    if (!userInfo.isAdmin) {
      props.history.push("/login-page");
    }
    if (successCreate) {
      props.history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listProducts());
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
  ]);

  const createProductHandler = () => {
    dispatch(createProduct());
  };

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delte the product")) {
      dispatch(deleteProduct(id));
    }
  };

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div className={classNames(classes.main, classes.mainDetail)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button component={Link} to="/" className={classes.button}>
              Go Back
            </Button>
            <Button onClick={createProductHandler} className={classes.button}>
              <i className="fas fa-plus"> </i> Create Product
            </Button>
          </GridItem>
        </GridContainer>
        <div className={classes.section}>
          <div className={classes.container1} style={{ overflowX: "auto" }}>
            {loadingDelete && <h2>Loading</h2>}
            {errorDelete && <h2>{error}</h2>}
            {loadingCreate && <h2>Loading</h2>}
            {errorCreate && <h2>{error}</h2>}
            {loading ? (
              <h2>Loading</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h3>Products</h3>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <strong>ID</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Name</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Price</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Category</strong>
                        </TableCell>
                        <TableCell>
                          <strong>Stock</strong>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {products.map((product) => (
                        <TableRow key={product._id}>
                          <TableCell>{product._id}</TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>{product.price}</TableCell>
                          <TableCell>{product.category}</TableCell>
                          <TableCell>{product.countInStock}</TableCell>
                          <TableCell>
                            <Button
                              justIcon
                              round
                              component={Link}
                              to={"/admin/product/" + product._id}
                              className={classes.button}
                            >
                              <i className="fas fa-edit"></i>
                            </Button>
                            <Button
                              justIcon
                              round
                              color="danger"
                              className={classes.button}
                              onClick={() => deleteHandler(product._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

ProductListPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
