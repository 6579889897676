import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ParallaxOnlyHeader from "components/Parallax/ParallaxOnlyHeader.js";
import PropTypes from "prop-types";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import SectionProducts from "../Components/Sections/SectionProducts.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  title2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
      marginTop: "12em",
      marginBottom: "12em",
    },
  },
}));

export default function SubProductsPage(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { ...rest } = props;
  const category = props.match.params.category;
  var categoryBg = "";
  //var imageSource = "";

  if (category == "cheesecakes") {
    categoryBg = "Чийзкейкове по поръчка";
    //imageSource = require("assets/img/cheesecakes1.jpeg").default;
  } else if (category == "cakes") {
    categoryBg = "Шоколад и шоколадови бонбони";
    //imageSource = require("assets/img/cakes.jpeg").default;
  } else if (category == "designcakes") {
    categoryBg = "Дизайнерски торти";
    //imageSource = require("assets/img/designcakes.jpeg").default;
  } else if (category == "pastries") {
    categoryBg = "Печива";
    //imageSource = require("assets/img/pastries.jpeg").default;
  } else if (category == "croissants") {
    categoryBg = "Без глутен & без захар по поръчка";
    //imageSource = require("assets/img/croissants1.jpeg").default;
  } else if (category == "saladsandjogurt") {
    categoryBg = "Салати и йогурт с плодове";
    //imageSource = require("assets/img/saladsandjogurt.jpeg").default;
  } else if (category == "freshjuices") {
    categoryBg = "Напитки";
    //imageSource = require("assets/img/freshjuices.jpeg").default;
  } else {
    categoryBg = "Няма такава категория";
  }

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <ParallaxOnlyHeader>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={[classes.title, classes2.title2].join(" ")}>
                  {categoryBg}
                </h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </ParallaxOnlyHeader>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionProducts category={category} />
      </div>
      <Footer />
    </div>
  );
}

SubProductsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      category: PropTypes.string.isRequired,
    }),
  }),
};
