import React, { useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import HorizontalNonLinearStepper from "components/HorizontalNonLinearStepper/HorizontalNonLinearStepper.js";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { savePaymentMethod, saveInvoiceData } from "../../actions/cartActions";
//import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";

//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import styles1 from "assets/jss/material-kit-react/customCheckboxRadioSwitch.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles1 = makeStyles(styles, styles1);
const useStyles2 = makeStyles((theme) => ({
  mainDetail2: {
    [theme.breakpoints.down("xs")]: {
      width: "min-content",
    },
  },
}));

export default function PaymentPage(props) {
  const classes = useStyles1();
  const classes2 = useStyles2();

  const wrapperDiv = classNames(
    classes.checkboxAndRadio,
    classes.checkboxAndRadioHorizontal
  );

  const cart = useSelector((state) => state.cart);
  const { shippingAddress, invoiceData, error, loading } = cart;

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  const [paymentMethod, setPaymentMethod] = useState("");
  const [companyName, setCompanyName] = useState(invoiceData.companyName);
  const [eik, setEik] = useState(invoiceData.eik);
  const [ddsNumber, setDdsNumber] = useState(invoiceData.ddsNumber);
  const [mol, setMol] = useState(invoiceData.mol);
  const [invoiceCity, setInvoiceCity] = useState(invoiceData.invoiceCity);
  const [invoiceAddress, setInvoiceAddress] = useState(
    invoiceData.invoiceAddress
  );
  const [invoiceChecked, setInvoiceChecked] = useState(false);
  const [selectedEnabled, setSelectedEnabled] = useState("");

  const dispatch = useDispatch();

  if (!shippingAddress.address) {
    props.history.push("/shipping");
  }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    dispatch(
      saveInvoiceData({
        companyName,
        eik,
        ddsNumber,
        mol,
        invoiceCity,
        invoiceAddress,
      })
    );
    props.history.push("/placeorder");
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  return (
    <div>
      <Header
        brand="Fresh & Cheese"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div
        id="here"
        className={classNames(
          classes.main,
          classes.mainDetail,
          classes2.mainDetail2
        )}
      >
        <div>
          {loading ? (
            <h2>Зареждане</h2>
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.dividerBottom}>
                  <HorizontalNonLinearStepper location={props.history} />
                </div>
                <Card className={classes[cardAnimaton]}>
                  <form onSubmit={submitHandler} className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Начин на плащане</h4>
                    </CardHeader>
                    <p className={classes.divider}>Как искате да платите?</p>
                    <CardBody>
                      <div className={wrapperDiv}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={
                                selectedEnabled === "radio button enabled"
                              }
                              onChange={(e) => {
                                setPaymentMethod(e.target.value),
                                  setSelectedEnabled("radio button enabled");
                              }}
                              value="credit card"
                              name="radio button enabled"
                              aria-label="A"
                            />
                          }
                          label="Плати с карта"
                        />
                        <div className={wrapperDiv}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedEnabled === "cash on delivery"}
                                onChange={(e) => {
                                  setPaymentMethod(e.target.value),
                                    setSelectedEnabled("cash on delivery");
                                }}
                                value="cash on delivery"
                                name="radio button unebled"
                                aria-label="A"
                              />
                            }
                            label="Наложен платеж"
                          />
                        </div>
                      </div>
                      <div className={wrapperDiv}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedEnabled === "Transfer on bank account"
                              }
                              onChange={(e) => {
                                setPaymentMethod(e.target.value),
                                  setSelectedEnabled(
                                    "Transfer on bank account"
                                  );
                              }}
                              value="Transfer on bank account"
                              name="radio button enabled"
                              aria-label="A"
                            />
                          }
                          label="Банков превод"
                        />
                      </div>
                    </CardBody>
                    <p className={classes.divider}>Данни за фактура</p>
                    <CardBody>
                      <div className={wrapperDiv}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={invoiceChecked}
                              onChange={(e) =>
                                setInvoiceChecked(e.target.checked)
                              }
                              value="invoiceChecked"
                            />
                          }
                          label="Фактура"
                        />
                      </div>
                    </CardBody>
                    {invoiceChecked == true && (
                      <CardBody>
                        <CustomInput
                          labelText="Име на фирма..."
                          id="companyName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { companyName },
                            value: companyName,
                            onChange: (e) => setCompanyName(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  business_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="ЕИК..."
                          id="eik"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { eik },
                            value: eik,
                            onChange: (e) => setEik(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  payment_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="ДДС Номер"
                          id="ddsNumber"
                          value={ddsNumber}
                          onChange={(e) => setDdsNumber(e.target.value)}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { ddsNumber },
                            value: ddsNumber,
                            onChange: (e) => setDdsNumber(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  payment_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                          }}
                        />
                        <CustomInput
                          labelText="МОЛ..."
                          id="mol"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { mol },
                            value: mol,
                            onChange: (e) => setMol(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  person_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="Град..."
                          id="invoiceCity"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { invoiceCity },
                            value: invoiceCity,
                            onChange: (e) => setInvoiceCity(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  location_city_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="Адрес на фирмата..."
                          id="invoiceAdderess"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { invoiceAddress },
                            value: invoiceAddress,
                            onChange: (e) => setInvoiceAddress(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  home_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CardBody>
                    )}
                    <CardFooter className={classes.cardFooter}>
                      <Button type="onsubmit" simple color="primary" size="lg">
                        Продължи
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </div>
      <Footer whiteFont />
    </div>
  );
}

PaymentPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
