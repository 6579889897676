import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import classNames from "classnames";
//import CustomInput from "components/CustomInput/CustomInput.js";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import {
  listProductDetails,
  updateProduct,
} from "../../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../../constants/productConstants";
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function ProductEditPage(props) {
  const classes = useStyles();

  const productId = props.match.params.id;

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0.0);
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [description, setDescription] = useState(0);
  const [uploading, setUpload] = useState(false);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  const productDetails = useSelector((state) => state.productDetails);
  const { error, loading, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    error: errorUpdate,
    loading: loadingUpdate,
    success: successUpdate,
  } = productUpdate;

  const dispatch = useDispatch();

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      props.history.push("/admin/productlist");
    } else {
      if (!product.name || product._id !== Number(productId)) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product.name);
        setPrice(product.price);
        setImage(product.image);
        setCategory(product.category);
        setCountInStock(product.countInStock);
        setDescription(product.description);
      }
    }
  }, [dispatch, product, productId, successUpdate, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        image,
        category,
        countInStock,
        description,
      })
    );
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    formData.append("product_id", productId);

    setUpload(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await axios.post(
        "/api/products/upload/",
        formData,
        config
      );
      setImage(data);
      setUpload(false);
    } catch (error) {
      setUpload(false);
    }
  };

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div className={classNames(classes.main, classes.mainDetail)}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button
              component={Link}
              to="/admin/productlist/"
              className={classes.button}
            >
              Go Back
            </Button>
          </GridItem>
        </GridContainer>
        <div className={classes.section}>
          <div className={classes.container1}>
            {loadingUpdate && <h2>Loading</h2>}
            {errorUpdate && <h2>{errorUpdate}</h2>}
            {loading ? (
              <h2>Loading</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <Card className={classes[cardAnimaton]}>
                    <form onSubmit={submitHandler} className={classes.form}>
                      <CardHeader
                        color="primary"
                        className={classes.cardHeader}
                      >
                        <h4>Edit Product</h4>
                      </CardHeader>
                      <CardBody>
                        <CustomInput
                          labelText="Name..."
                          id="name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "name",
                            inputRef: { name },
                            value: name,
                            onChange: (e) => setName(e.target.value),
                          }}
                        />
                        <CustomInput
                          labelText="Price..."
                          id="price"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "number",
                            inputRef: { price },
                            value: price,
                            onChange: (e) => setPrice(e.target.value),
                          }}
                        />
                        <CustomInput
                          labelText="Image..."
                          id="image"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            inputRef: { image },
                            value: image,
                            onChange: (e) => setImage(e.target.value),
                          }}
                        />
                        <CustomInput
                          labelText="Choose..."
                          id="image-file"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "file",
                            onChange: uploadFileHandler,
                          }}
                        />
                        {uploading && <p>Loading</p>}
                        <CustomInput
                          labelText="Count in Stock..."
                          id="countInStock"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "number",
                            inputRef: { countInStock },
                            value: countInStock,
                            onChange: (e) => setCountInStock(e.target.value),
                          }}
                        />
                        <FormControl style={{ minWidth: 300, float: "left " }}>
                          <InputLabel id="Category">Category</InputLabel>
                          <Select
                            label="Category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <MenuItem value="cakes">cakes</MenuItem>
                            <MenuItem value="cheesecakes">cheesecakes</MenuItem>
                            <MenuItem value="designcakes">designcakes</MenuItem>
                            <MenuItem value="pastries">pastries</MenuItem>
                            <MenuItem value="croissants">croissants</MenuItem>
                            <MenuItem value="saladsandjogurt">
                              saladsandjogurt
                            </MenuItem>
                            <MenuItem value="freshjuices">
                              Fresh Juices
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <CustomInput
                          labelText="Description..."
                          id="description"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            multiline: true,
                            inputRef: { description },
                            value: description,
                            onChange: (e) => setDescription(e.target.value),
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          type="onsubmit"
                          simple
                          color="primary"
                          size="lg"
                        >
                          Update
                        </Button>
                      </CardFooter>
                    </form>
                  </Card>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

ProductEditPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
