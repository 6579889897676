import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import ParallaxOnlyHeader from "components/Parallax/ParallaxOnlyHeader.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";
import SectionCard from "./Sections/SectionCard.js";
import CookieConsent from "react-cookie-consent";

import styles from "assets/jss/material-kit-react/views/components.js";
const useStyles1 = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  brand2: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "-5em",
      minWidth: "16em",
      marginLeft: "-8em",
    },
  },
  title2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  subtitle2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      marginBottom: "-6em",
    },
  },
}));

export default function Components(props) {
  const classes = useStyles1();
  const classes2 = useStyles2();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <ParallaxOnlyHeader style={{ padding: "160px" }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div
                className={[classes.brand, classes2.brand2].join(" ")}
                style={{
                  display: { md: "none" },
                }}
              >
                {/*<h1 className={[classes.title, classes2.title2].join(" ")}>
                  Cheese & Fresh Bar
                </h1>*/}
                <h1 className={[classes.title, classes2.title2].join(" ")}>
                  Съвършеният чийзкейк!
                </h1>
                <h3
                  className={[classes.subtitle, classes2.subtitle2].join(" ")}
                >
                  Без изкуствени оцветители! Без фондан! Без заготовки!
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </ParallaxOnlyHeader>

      <div className={classNames(classes.main, classes.mainRaised)}>
        {/*<SectionBasics />
        <SectionNavbars />
        <SectionTabs />
        <SectionPills />
        <SectionNotifications />
        <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload />*/}
        <SectionCard />
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Ok!"
        cookieName="<cookieName>"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
        <span style={{ fontSize: "10px" }}></span>
      </CookieConsent>
      <Footer />
    </div>
  );
}
