import React, { useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import HorizontalNonLinearStepper from "components/HorizontalNonLinearStepper/HorizontalNonLinearStepper.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import classNames from "classnames";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingAddress } from "../../actions/cartActions";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles1 = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  mainDetail2: {
    [theme.breakpoints.down("xs")]: {
      width: "min-content",
    },
  },
}));

export default function ShippingPage(props) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress, error, loading } = cart;

  const dispatch = useDispatch();
  //const date = new Date(new Date().getTime() + 172800000);
  const date1 = new Date();
  const date = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate() + 2
  );

  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
  const [phoneNumber, setPhoneNumber] = useState(shippingAddress.phoneNumber);
  let [shippingDate, setShippingDate] = useState(date);
  const [takeOnPlace, setTakeOnPlace] = useState(false);
  const [takeOnAddress, setTakeOnAddress] = useState(false);
  const [comment, setComment] = useState(" ");
  const classes = useStyles1();
  const classes2 = useStyles2();

  const submitHandler = (e) => {
    e.preventDefault();
    shippingDate =
      shippingDate.getFullYear() +
      "-" +
      (parseInt(shippingDate.getMonth()) + 1).toString() +
      "-" +
      shippingDate.getDate();

    dispatch(
      saveShippingAddress({
        address,
        city,
        postalCode,
        phoneNumber,
        shippingDate,
        comment,
      })
    );
    props.history.push("/payment");
  };

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  function handleClick(event) {
    if (event.target.value == "takeOnPlace") {
      setTakeOnPlace(event.target.checked);
      setTakeOnAddress(false);
      setAddress("пл.Възраждане 8 ");
      setCity("София");
      setPostalCode("1000");
    } else {
      setTakeOnAddress(event.target.checked);
      setTakeOnPlace(false);
    }
  }

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  return (
    <div>
      <Header
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
      />
      <div
        id="here"
        className={classNames(
          classes.main,
          classes.mainDetail,
          classes2.mainDetail2
        )}
      >
        <div>
          {loading ? (
            <h2>Зареждане</h2>
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.dividerBottom}>
                  <HorizontalNonLinearStepper location={props.history} />
                </div>
                <Card className={classes[cardAnimaton]}>
                  <form onSubmit={submitHandler} className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Доставка</h4>
                    </CardHeader>
                    <p className={classes.divider}>Къде да доставим?</p>
                    <CardBody>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={takeOnPlace}
                            onChange={handleClick}
                            value="takeOnPlace"
                          />
                        }
                        label="Вземи на място"
                      />
                    </CardBody>

                    {takeOnPlace == true && (
                      <CardBody>
                        гр. София, пл. Възраждане 8
                        <CustomInput
                          labelText="Телефон..."
                          id="phoneNumber"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { phoneNumber },
                            value: phoneNumber,
                            onChange: (e) => setPhoneNumber(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  phone_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CardBody>
                    )}
                    <CardBody>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled
                            checked={takeOnAddress}
                            onChange={handleClick}
                            value="takeOnAddress"
                          />
                        }
                        label="Поръчай до адрес"
                      />
                    </CardBody>
                    {takeOnAddress == true && (
                      <CardBody>
                        <CustomInput
                          labelText="Адрес..."
                          id="address"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { address },
                            value: address,
                            onChange: (e) => setAddress(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  home_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="Град..."
                          id="city"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { city },
                            value: city,
                            onChange: (e) => setCity(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  location_city_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <CustomInput
                          labelText="Пощенски код"
                          id="postalCode"
                          value={postalCode}
                          onChange={(e) => setPostalCode(e.target.value)}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { postalCode },
                            value: postalCode,
                            onChange: (e) => setPostalCode(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  local_post_office_outline
                                </Icon>
                              </InputAdornment>
                            ),
                            autoComplete: "off",
                          }}
                        />
                        <CustomInput
                          labelText="Телефон..."
                          id="phoneNumber"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            required: true,
                            type: "text",
                            inputRef: { phoneNumber },
                            value: phoneNumber,
                            onChange: (e) => setPhoneNumber(e.target.value),
                            endAdornment: (
                              <InputAdornment position="end">
                                <Icon className={classes.inputIconsColor}>
                                  phone_outline
                                </Icon>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CardBody>
                    )}
                    <p className={classes.divider}>Кога да доставим?</p>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <CardBody>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          label="Изберете ден"
                          value={shippingDate}
                          minDate={date}
                          onChange={(date) => setShippingDate(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </CardBody>
                    </MuiPickersUtilsProvider>
                    <p className={classes.divider}>Коментар</p>
                    <CardBody>
                      <CustomInput
                        labelText="..."
                        id="comment"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          multiline: true,
                          inputRef: { comment },
                          value: comment,
                          onChange: (e) => setComment(e.target.value),
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button type="onsubmit" simple color="primary" size="lg">
                        Продължи
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </div>
      <Footer whiteFont />
    </div>
  );
}

ShippingPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
