import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import Eco from "@material-ui/icons/Eco";
import LocationOn from "@material-ui/icons/LocationOn";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles1 = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  imgFluid: {
    width: "100%",
    //maxWidth: "290px",
    height: "auto",
    //maxHeight: "200px",
  },
  imgRounded: {
    borderRadius: "6px !important",
  },
  imgRaised: {
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  subtitle2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      marginBottom: "-6em",
    },
  },
}));

export default function ProductSection() {
  const classes = useStyles1();
  const classes2 = useStyles2();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={6}>
            <h2 className={classes2.title2}>
              Най-вкусните чийзкейкове в града!❤
            </h2>
            <h5 className={classes.description}>
              Съвършеният чийзкейк! - Висококачествени, проверени суровини -
              Белгийски шоколад - Естествени аромати и оцветители - Опция за
              диабетици - без захар/ без глутен/ кето/ веган - Без изкуствени
              оцветители, без ГМО, без фондан, без заготовки, без палмова
              мазнина, без маргарин!
            </h5>
          </GridItem>
          <GridItem xs={12} sm={6} md={6}>
            <img
              src={require("assets/img/store.jpg").default}
              alt="..."
              width={450}
              height={350}
              className={
                classes2.imgRaised +
                " " +
                classes2.imgRounded +
                " " +
                classes2.imgFluid
              }
            />
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title=""
                description="Ние сме малка, семейна сладкарничка, която предлага висококачествени
                и изключително вкусни десерти и печива!"
                icon={SupervisedUserCircle}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title=""
                description="Не използваме изкуствени
                оцветители, не използваме ГМО, не използваме фондан!"
                icon={Eco}
                iconColor="success"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <InfoArea
                title=""
                description="Може да ни
                намерите в гр. София, пл. Възраждане 8 или да се свържете с нас на
                телефони 0882240470 и 0882715340."
                icon={LocationOn}
                iconColor="danger"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
