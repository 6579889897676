/*eslint-disable*/
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import { logout } from "../../actions/userActions";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {
  Apps,
  ShoppingCart,
  Person,
  Cake,
  LocationOn,
} from "@material-ui/icons";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 58,
    top: -5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);

export default function HeaderLinks(props) {
  const userLogin = useSelector((state) => state.userLogin);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  const logoutHandler = () => {
    dispatch(logout());
  };
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="cheese"
          title="Чийзкейкове"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/subproducts/cheesecakes"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge>
              <i className={classes.listItem}>Чийзкейкове по поръчка</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="croissants"
          title="Без глутен & без захар по поръчка"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/subproducts/croissants"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge>
              <i className={classes.listItem}>Без глутен & без захар по поръчка</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="designcakes"
          title="Дизайнерски торти"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/subproducts/designcakes"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge>
              <i className={classes.listItem}>Дизайнерски торти</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="pastries"
          title="Печива"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/subproducts/pastries"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge>
              <i className={classes.listItem}>Печива</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="about"
          title="За нас"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/aboutus"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge>
              <Cake className={classes.icons} />
              <i className={classes.listItem}>За нас</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="contacts"
          title="Свържи се с нас"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/contacts"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge>
              <LocationOn className={classes.icons} />
              <i className={classes.listItem}>Контакти</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Последвай ни във Фейсбук"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/Cheeseandfresh/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Последвай ни в Инстаграм"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/cheeseandfresh/"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>
      {userInfo ? (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText={userInfo.name}
            id="username"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Person}
            dropdownList={[
              <Link to="/profile-page" className={classes.dropdownLink}>
                Профил
              </Link>,
              <a onClick={logoutHandler} className={classes.dropdownLink}>
                Изход
              </a>,
            ]}
          />
        </ListItem>
      ) : (
        <ListItem className={classes.listItem}>
          <Tooltip
            id="login-tooltip"
            title="Моля, влез в профила"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button component={Link} to="/login-page" color="transparent">
              <i className={classes.socialIcons + " fas fa-user"} />
            </Button>
          </Tooltip>
        </ListItem>
      )}
      {userInfo && userInfo.isAdmin && (
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Админ"
            id="username"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Person}
            dropdownList={[
              <Link to="/admin/userlist" className={classes.dropdownLink}>
                Потребители
              </Link>,
              <Link to="/admin/productlist" className={classes.dropdownLink}>
                Продукти
              </Link>,
              <Link to="/admin/orderlist" className={classes.dropdownLink}>
                Поръчки
              </Link>,
            ]}
          />
        </ListItem>
      )}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="cart"
          title="Продължи към плащане"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            component={Link}
            to="/cart"
            color="transparent"
            className={classes.navLink}
          >
            <StyledBadge
              badgeContent={cartItems ? cartItems.length : 0}
              color="secondary"
            >
              <ShoppingCart className={classes.icons} />
              <i className={classes.listItem}>Количка</i>
            </StyledBadge>
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}

{
  /*
    <Link to="/subproducts/cakes" className={classes.dropdownLink}>
      Шоколад и шоколадови бонбони
    </Link>,
    <Link
      to="/subproducts/freshjuices"
      className={classes.dropdownLink}
    >
      Напитки
    </Link>,
    <Link
      to="/subproducts/saladsandjogurt"
      className={classes.dropdownLink}
    >
      Салати и йогурт с плодове
    </Link>,
    <ListItem className={classes.listItem}>
      <CustomDropdown
        noLiPadding
        buttonText="Меню"
        buttonProps={{
          className: classes.navLink,
          color: "transparent",
        }}
        buttonIcon={Apps}
        dropdownList={[
          <Link
            to="/subproducts/cheesecakes"
            className={classes.dropdownLink}
          >
            Чийзкейкове
          </Link>,
          <Link
            to="/subproducts/designcakes"
            className={classes.dropdownLink}
          >
            Дизайнерски торти
          </Link>,
          <Link to="/subproducts/pastries" className={classes.dropdownLink}>
            Браунис, сладки и печива
          </Link>,
          <Link to="/subproducts/croissants" className={classes.dropdownLink}>
            Гризини и соленки
          </Link>,
        ]}
      />
    </ListItem>
  */
}
