import React, { useEffect } from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
//import Alert from "@mui/material/Alert";
//import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/exampleStyle.js";
import { listProducts } from "../../../actions/productActions";

const useStyles = makeStyles(styles);

export default function SectionExamples() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { error, loading } = productList;

  useEffect(() => {
    dispatch(listProducts());
  }, [dispatch]);

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {loading ? (
          <h2>Зареждане</h2>
        ) : error ? (
          <h2>{error}</h2>
        ) : (
          <GridContainer justify="center">
            {/*
            <GridItem xs={12} sm={12} md={12}>
              <Alert severity="error" style={{ marginBottom: "30px" }}>
                <h5 className={classes.title}>
                  Годишен отпуск на сладкарницата: 01.08.22-31.08.22. Поръчки за
                  чийзкейкове, торти и кетъринг се приемат след 15.08.22.
                </h5>
              </Alert>
            </GridItem>
          */}
            <GridItem xs={12} sm={6} md={3}>
              <Link to={"/subproducts/cheesecakes"} className={classes.link}>
                <img
                  src={require("assets/img/cheesecakes.jpeg").default}
                  alt="..."
                  width={350}
                  height={350}
                  className={
                    classes.imgRaised +
                    " " +
                    classes.imgRounded +
                    " " +
                    classes.imgFluid
                  }
                />
              </Link>
              <h3 className={classes.title}>Чийзкейкове по поръчка</h3>
              <div>
                <br></br>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to={"/subproducts/croissants"} className={classes.link}>
                <img
                  src={require("assets/img/croissants.jpeg").default}
                  alt="..."
                  width={350}
                  height={350}
                  className={
                    classes.imgRaised +
                    " " +
                    classes.imgRounded +
                    " " +
                    classes.imgFluid
                  }
                />
              </Link>
              <h3 className={classes.title}>
                Без глутен & без захар по поръчка
              </h3>
              <div>
                <br></br>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to={"/subproducts/designcakes"} className={classes.link}>
                <img
                  src={require("assets/img/designcakes.jpeg").default}
                  alt="..."
                  width={350}
                  height={350}
                  className={
                    classes.imgRaised +
                    " " +
                    classes.imgRounded +
                    " " +
                    classes.imgFluid
                  }
                />
              </Link>
              <h3 className={classes.title}>Дизайнерски торти</h3>
              <div>
                <br></br>
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Link to={"/subproducts/pastries"} className={classes.link}>
                <img
                  src={require("assets/img/pastries.jpeg").default}
                  alt="..."
                  width={350}
                  height={350}
                  className={
                    classes.imgRaised +
                    " " +
                    classes.imgRounded +
                    " " +
                    classes.imgFluid
                  }
                />
              </Link>
              <h3 className={classes.title}>Печива</h3>
              <div>
                <br></br>
              </div>
            </GridItem>
          </GridContainer>
        )}
      </div>
    </div>
  );
}

{
  /*

  <GridItem xs={12} sm={6} md={3}>
    <Link to={"/subproducts/cakes"} className={classes.link}>
      <img
        src={require("assets/img/cakes.jpeg").default}
        alt="..."
        width={350}
        height={350}
        className={
          classes.imgRaised +
          " " +
          classes.imgRounded +
          " " +
          classes.imgFluid
        }
      />
    </Link>
    <h3 className={classes.title}>Шоколад и шоколадови бонбони</h3>
    <div>
      <br></br>
    </div>
  </GridItem>
  <GridItem xs={12} sm={6} md={3}>
    <Link
      to={"/subproducts/saladsandjogurt"}
      className={classes.link}
    >
      <img
        src={require("assets/img/saladsandjogurt.jpeg").default}
        alt="..."
        width={350}
        height={350}
        className={
          classes.imgRaised +
          " " +
          classes.imgRounded +
          " " +
          classes.imgFluid
        }
      />
    </Link>
    <h3 className={classes.title}>Салати и йогурт с плодове</h3>
    <div>
      <br></br>
    </div>
  </GridItem>
  <GridItem xs={12} sm={6} md={3}>
    <Link to={"/subproducts/freshjuices"} className={classes.link}>
      <img
        src={require("assets/img/freshjuices.jpeg").default}
        alt="..."
        width={350}
        height={350}
        className={
          classes.imgRaised +
          " " +
          classes.imgRounded +
          " " +
          classes.imgFluid
        }
      />
    </Link>
    <h3 className={classes.title}>Напитки</h3>
    <div>
      <br></br>
    </div>
  </GridItem>
*/
}
