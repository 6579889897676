import React, { useEffect, Fragment, useState } from "react";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { addToCart, removeFromCart } from "../../actions/cartActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles1 = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  mainDetail2: {
    [theme.breakpoints.down("xs")]: {
      width: "fit-content",
    },
  },
}));

function CartPage(props) {
  const classes = useStyles1();
  const classes2 = useStyles2();
  // const { ...rest } = props;
  const productId = props.match.params.id;
  const qty = props.location.search
    ? Number(props.location.search.split("=")[1])
    : 1;
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems, error, loading } = cart;
  const [pieseQty, setPieseQty] = useState(() => {
    var initialState;
    initialState = 8;
    return initialState;
  });
  const [cakePieseQty, setCakePieseQty] = useState(() => {
    var initialState;
    cartItems.map((item) => {
      if (item.price == 90) {
        initialState = 12;
      }
      if (item.price == 100) {
        initialState = 14;
      }
    });
    return initialState;
  });
  const cheeseCakeSizes = [8, 10, 12, 14];
  const designCakesSizes = [12, 14];
  const designCakesNumber = [1, 2, 3, 4, 5];

  //const data = props.location.state;
  //console.log(data);

  const cakePrice = [];
  //const cheesecakePrice = [];
  const othersPrice = [];
  //var totalPrice;

  // function add(accumulator, a) {
  //   return accumulator + a;
  // }

  function designCakesQty() {
    cartItems.map((item) => {
      if (item.category == "designcakes") {
        cakePrice.push(item.price);
        if (cakePieseQty == 12) {
          item.price = 90;
        } else {
          item.price = 100;
        }
      }
      if (item.category == "cheesecakes") {
        //console.log(item.price);
        //cheesecakePrice.length = 0;
        //cheesecakePrice.push(item.price);
        //cakePrice.push(item.price);
        // if (pieseQty) {
        //   console.log(pieseQty);
        //item.price = item.price * pieseQty;
        // }
        //cheesecakePrice.push(item.price * pieseQty * item.qty);
        //console.log(item.price);
      } else {
        othersPrice.push(item.price * item.qty);
      }
      //totalPrice = cakePrice.reduce(add, 0) + othersPrice.reduce(add, 0);
    });
  }
  designCakesQty();

  // function cheeseCakesQty() {
  //   cartItems.map((item) => {
  //     if (item.category == "cheesecakes") {
  //       console.log(item);
  //       item.qty = 8;
  //       console.log(item.qty);
  //     }
  //   });
  // }

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty, pieseQty, cartItems));
    }
  }, [dispatch, productId, qty, pieseQty]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    props.history.push(`/shipping`);
  };

  return (
    <div>
      <div>
        <Header
          brand="Cheese & Fresh Bar"
          rightLinks={<HeaderLinks />}
          fixed
          color="white"
          changeColorOnScroll={{
            height: 400,
            color: "white",
          }}
        />
      </div>
      <div
        className={classNames(
          classes.main,
          classes.mainDetail,
          classes2.mainDetail2
        )}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button component={Link} to="/" className={classes.button}>
              Назад
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <h3>Количка</h3>
          </GridItem>
        </GridContainer>
        <div className={classes.section}>
          <div className={classes.container1}>
            {loading ? (
              <h2>Зареждане</h2>
            ) : error ? (
              <h2>{error}</h2>
            ) : (
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  {cartItems == 0 ? (
                    <h2>Количката е празна. Обратно към продуктите!</h2>
                  ) : (
                    <Table>
                      <TableBody>
                        {cartItems.map((item) => (
                          <TableRow key={item.product}>
                            <TableCell>
                              <img src={item.image} width="60" height="60" />
                            </TableCell>
                            <TableCell>
                              <Link to={`/product-details/${item.product}`}>
                                {item.name}
                              </Link>
                            </TableCell>
                            <TableCell>{item.price} лв.</TableCell>
                            <TableCell>
                              {item.category == "cheesecakes" ? (
                                <Fragment>
                                  <FormControl>
                                    <InputLabel id="demo-simple-select-label">
                                      Парчета
                                    </InputLabel>
                                    <Select
                                      label="PieseQuantity"
                                      value={pieseQty}
                                      onChange={(e) => {
                                        setPieseQty(e.target.value);
                                        dispatch(
                                          addToCart(
                                            item.product,
                                            Number(item.qty),
                                            Number(e.target.value)
                                          )
                                        );
                                      }}
                                    >
                                      {cheeseCakeSizes.map((x) => (
                                        <MenuItem key={x} value={x}>
                                          {x}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl>
                                    <InputLabel id="demo-simple-select-label">
                                      Количество
                                    </InputLabel>
                                    <Select
                                      label="Quantity"
                                      value={item.qty}
                                      onChange={(e) =>
                                        dispatch(
                                          addToCart(
                                            item.product,
                                            Number(e.target.value),
                                            Number(item.pieseQty)
                                          )
                                        )
                                      }
                                    >
                                      {designCakesNumber.map((x) => (
                                        <MenuItem key={x} value={x}>
                                          {x}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Fragment>
                              ) : item.category == "designcakes" ? (
                                <Fragment>
                                  <FormControl>
                                    <InputLabel id="demo-simple-select-label">
                                      Парчета
                                    </InputLabel>
                                    <Select
                                      label="PieseQuantity"
                                      value={cakePieseQty}
                                      onChange={(e) => {
                                        setCakePieseQty(e.target.value);
                                        dispatch(
                                          addToCart(
                                            item.product,
                                            Number(item.qty),
                                            Number(e.target.value)
                                          )
                                        );
                                      }}
                                    >
                                      {designCakesSizes.map((x) => (
                                        <MenuItem key={x} value={x}>
                                          {x}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                  <FormControl>
                                    <InputLabel id="demo-simple-select-label">
                                      Количество
                                    </InputLabel>
                                    <Select
                                      label="Quantity"
                                      value={item.qty}
                                      onChange={(e) =>
                                        dispatch(
                                          addToCart(
                                            item.product,
                                            Number(e.target.value),
                                            Number(item.pieseQty)
                                          )
                                        )
                                      }
                                    >
                                      {designCakesNumber.map((x) => (
                                        <MenuItem key={x} value={x}>
                                          {x}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Fragment>
                              ) : (
                                <FormControl>
                                  <InputLabel id="demo-simple-select-label">
                                    Количество
                                  </InputLabel>
                                  <Select
                                    label="Quantity"
                                    value={item.qty}
                                    onChange={(e) =>
                                      dispatch(
                                        addToCart(
                                          item.product,
                                          Number(e.target.value),
                                          Number(item.pieseQty)
                                        )
                                      )
                                    }
                                  >
                                    {[...Array(item.countInStock).keys()].map(
                                      (x) => (
                                        <MenuItem key={x + 1} value={x + 1}>
                                          {x + 1}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            </TableCell>
                            <TableCell>
                              <Button
                                onClick={() =>
                                  removeFromCartHandler(item.product)
                                }
                                className={classes.button}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <h3>
                    Общо ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                    продукта в количката
                  </h3>
                  <Table style={{ borderWidth: "1px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {cartItems
                            .reduce((acc, item) => {
                              if (item.category == "designcakes") {
                                return acc + item.qty * item.price;
                              } else {
                                return (
                                  acc + item.qty * item.price * item.pieseQty
                                );
                              }
                            }, 0)
                            .toFixed(2)}
                          лв.
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Button
                            className={classes.button}
                            fullWidth
                            disabled={cartItems.length === 0}
                            onClick={checkoutHandler}
                          >
                            Към поръчката{" "}
                            <i
                              style={{ marginLeft: "1em" }}
                              className="fas fa-shopping-bag"
                            ></i>
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </GridItem>
              </GridContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

CartPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  }).isRequired,
};

export default CartPage;
