import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
//import GridContainer from "components/Grid/GridContainer.js";
//import GridItem from "components/Grid/GridItem.js";
//import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ParallaxOnlyHeader from "components/Parallax/ParallaxOnlyHeader.js";

//import styles from "assets/jss/material-kit-react/views/landingPage.js";
import styles from "assets/jss/material-kit-react/views/components.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
//import TeamSection from "./Sections/TeamSection.js";
//import WorkSection from "./Sections/WorkSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme) => ({
  brand2: {
    [theme.breakpoints.down("xs")]: {
      //marginTop: "-5em",
      minWidth: "16em",
      //marginLeft: "-8em",
    },
  },
  title2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  subtitle2: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
      marginBottom: "-6em",
    },
  },
}));

export default function AboutUsPage(props) {
  const classes = useStyles();
  const classes2 = useStyles2();
  const { ...rest } = props;
  return (
    <div>
      <Header
        routes={dashboardRoutes}
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <ParallaxOnlyHeader>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className={[classes.brand, classes2.brand2].join(" ")}>
                <h1 className={[classes.title, classes2.title2].join(" ")}>
                  За нас
                </h1>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </ParallaxOnlyHeader>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}
