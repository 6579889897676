import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";
import { Provider } from "react-redux";
import store from "./store";
// pages for this product
import Components from "views/Components/Components.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import RegisterPage from "views/RegisterPage/RegisterPage.js";
import ProductPage from "views/ProductPage/ProductPage.js";
import CartPage from "views/CartPage/CartPage.js";
import ShippingPage from "views/ShippingPage/ShippingPage.js";
import PaymentPage from "views/PaymentPage/PaymentPage.js";
import PlaceOrderPage from "views/PlaceOrderPage/PlaceOrderPage.js";
import OrderPage from "views/OrderPage/OrderPage.js";
import OrderListPage from "views/OrderListPage/OrderListPage.js";
import SubProductsPage from "views/SubProductsPage/SubProductsPage.js";
import UserListPage from "views/UserListPage/UserListPage.js";
import ProductListPage from "views/ProductListPage/ProductListPage.js";
import ProductEditPage from "views/ProductEditPage/ProductEditPage.js";
import UserEditPage from "views/UserEditPage/UserEditPage.js";
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import ContactsPage from "views/ContactsPage/ContactsPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <Route path="/landing-page" component={LandingPage} />
        <Route path="/profile-page" component={ProfilePage} />
        <Route path="/login-page" component={LoginPage} />
        <Route path="/register-page" component={RegisterPage} />
        <Route path="/product-details/:id" component={ProductPage} />
        <Route path="/shipping" component={ShippingPage} />
        <Route path="/payment" component={PaymentPage} />
        <Route path="/placeorder" component={PlaceOrderPage} />
        <Route path="/order/:id?" component={OrderPage} />
        <Route path="/cart/:id?" component={CartPage} />
        <Route path="/subproducts/:category?" component={SubProductsPage} />
        <Route path="/" component={Components} exact />
        <Route path="/admin/userlist" component={UserListPage} />
        <Route path="/admin/user/:id" component={UserEditPage} />
        <Route path="/admin/productlist" component={ProductListPage} />
        <Route path="/admin/product/:id" component={ProductEditPage} />
        <Route path="/admin/orderlist" component={OrderListPage} />
        <Route path="/aboutus" component={AboutUsPage} />
        <Route path="/contacts" component={ContactsPage} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
