import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/userActions";

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import modalStyle from "assets/jss/material-kit-react/modalStyle.js";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);
const useStylesModal = makeStyles(modalStyle);

export default function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const dispatch = useDispatch();

  const redirect = props.location.search ? location.search.split("=")[1] : "/";
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      props.history.goBack();
      //props.history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (agreeTerms == true) {
      dispatch(login(email, password));
    } else {
      setMessage("Моля, приеми общите условия!");
    }
  };

  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();
  const classesModal = useStylesModal();

  const { ...rest } = props;

  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Cheese & Fresh Bar"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      >
        <div className={classes.container}>
          {loading ? (
            <h2>Зареждане</h2>
          ) : error ? (
            <h2>{error}</h2>
          ) : (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[cardAnimaton]}>
                  <form onSubmit={submitHandler} className={classes.form}>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Влез в профила си</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Имейл..."
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          type: "email",
                          inputRef: { email },
                          onChange: (e) => setEmail(e.target.value),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomInput
                        labelText="Парола"
                        id="pass"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          required: true,
                          type: "password",
                          inputRef: { password },
                          onChange: (e) => setPassword(e.target.value),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off",
                        }}
                      />
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={agreeTerms}
                              onChange={(e) => setAgreeTerms(e.target.checked)}
                              value="agreeTerms"
                            />
                          }
                          label={
                            <label>
                              Съгласявам се с{" "}
                              <a onClick={() => setModal(true)}>
                                общите условия
                              </a>
                            </label>
                          }
                        />
                      </div>
                      {message && <p className={classes.divider}>{message}</p>}
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button type="onsubmit" simple color="primary" size="lg">
                        Вход
                      </Button>
                    </CardFooter>
                    <div>
                      <Button
                        component={Link}
                        to={
                          redirect
                            ? `/register-page?redirect=${redirect}`
                            : "/register-page"
                        }
                        simple
                        color="primary"
                        size="sm"
                      >
                        Регистрирай се
                      </Button>
                    </div>
                  </form>
                </Card>
                <Dialog
                  classes={{
                    root: classesModal.center,
                    paper: classesModal.modal,
                  }}
                  open={modal}
                  keepMounted
                  onClose={() => setModal(false)}
                  aria-labelledby="modal-slide-title"
                  aria-describedby="modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classesModal.modalHeader}
                  >
                    <h4 className={classesModal.modalTitle}>Общи условия</h4>
                  </DialogTitle>
                  <DialogContent
                    id="modal-slide-description"
                    className={classesModal.modalBody}
                  >
                    <h5>
                      Настоящите „Общи условия“ определят условията и
                      взаимоотношенията между Чийз енд фреш бар ООД (собственик
                      на онлайн магазина за продажба на хранителни продукти
                      www.cheeseandfresh.com ) и Потребителите (Клиентите),
                      ползващи онлайн магазина www.cheeseandfresh.com  за
                      покупка на стоки. При маркиране на полето „Прочетох и съм
                      съгласен с условията за ползване“ Клиентът се съгласява,
                      приема и задължава да спазва описаните по-долу Общи
                      условия. Чл. 1 Идентификация и регистрация Всеки един
                      потребител на сайта www.cheeseandfresh.com може да направи
                      поръчка в качеството си на РЕГИСТРИРАН ПОТРЕБИТЕЛ или като
                      ГОСТ НА САЙТА. За да направите поръчка като гост на сайта,
                      е нужно да посочите адрес за доставка, име и фамилия на
                      получателя, Вашия телефон за връзка, Ваш e-mail и Вашите
                      имена, в случай че са различни от тези на получателя.
                      Подадените данни ще бъдат съхранени до момента на
                      доставката. За регистрация в сайта е нужно да изберете
                      уникално потребителско име и парола, да посочите адрес за
                      доставка, име и фамилия, e-mail, телефон за връзка. Тези
                      данни ще бъдат запаметени в нашата система с цел да се
                      улесни всяка нова поръчка. При всяка нова поръчка ще ви
                      бъде предоставена възможността да променяте името на
                      получателя и адреса за доставка. Чийз енд фреш бар ООД
                      запазва правото да проверява достоверността на данните,
                      въведени от Клиента, и по-специално самоличността и
                      фактическия адрес на Клиента. Чл. 2 Лични данни В
                      системата на онлайн магазина www.cheeseandfresh.com се
                      съхраняват единствено данни, свързани с изпълнението на
                      направената поръчка и нейната доставка. Чийз енд фреш бар
                      ООД гарантира на своите Потребители конфиденциалността на
                      предоставената информация и лични данни. Чийз енд фреш бар
                      ООД се задължава да не предоставя на трети страни личните
                      данни на Потребителя, освен в случаите, когато тази
                      информация е изискана от държавни органи или длъжностни
                      лица. В тези случаи Чийз енд фреш бар ООД е задължен да
                      предостави изисканата информация по силата на закона. С
                      приемането на настоящите Общи условия, Потребителят дава
                      своето безусловно и безсрочно съгласие, предоставените от
                      него лични данни да бъдат събирани, съхранявани,
                      обработвани и използвани от Чийз енд фреш бар ООД за
                      целите на изпълнение на договора за покупко-продажба и
                      доставка на поръчаните стоки. Чл. 3 Поръчка Можете да
                      поръчате стока в www.cheeseandfresh.com по всяко време от
                      денонощието, включително в събота и неделя и официални
                      празници. 3.1. За да направи поръчка в
                      www.cheeseandfresh.com, Потребителят трябва да посочи:
                      валиден адрес на своя електронна поща, вярно попълнен
                      адрес за доставка на стоката и фактуриране, вярно
                      попълнени данни за контакт – две имена, телефон. Клиентът
                      избира продуктите и услугите, които желае да закупи, след
                      това потвърждава своята поръчка, като въвежда номер на
                      кредитна карта и датата на нейното изтичане, съгласно
                      условията предвидени в Чл. 4 Цени, след това клика върху
                      бутон “Потвърди” и маркира полето „Прочетох и съм съгласен
                      с условията за ползване“, което е равносилно на сключване
                      на договор между двете страни. Поръчката се регистрира
                      незабавно, след което номерът на поръчката се съобщава на
                      Клиента и на неговата електронна поща. След клик върху
                      бутон „Поръчай“ Потребителят се съгласява да закупи
                      стоките, намиращи се в кошницата. Това действие има
                      правно-обвързваща сила. При успешно направена поръчка,
                      Потребителят получава потвърждение на поръчката си на
                      посочения e-mail. В потвърждението по e-mail се посочват
                      вида на поръчаната стока, номерът на поръчката, датата и
                      часът, в който е направена поръчката. Потвърждение по
                      телефон се осъществява единствено тогава, когато е
                      необходима допълнителна информация или уточнение относно
                      поръчката. Чийз енд фреш бар ООД се ангажира да Ви изпрати
                      на посочения e-mail номер на пратката за проследяване на
                      нейното местоположение по всяко време. 3.2. Отказ на
                      поръчка може да се направи по телефона 0882715340 или на
                      имейл: cheeseandfresh@gmail.com, като Клиентът достави
                      информация за номера на направената поръчка, вида на
                      стоката, двете си имена, цената на поръчката. Отказ на
                      поръчката може да се направи до момента, в който пратката
                      все още не е била приета от куриер. В момента, в който
                      пратката вече е била приета от куриера, за извършване на
                      доставка, не могат да се направят промени по заявената
                      поръчка. 3.3. В случай че поръчаната стока не е в
                      наличност или поради друга обективна причина Чийз енд фреш
                      бар ООД има правото да откаже поръчка, като се ангажира да
                      уведоми Клиента в рамките на един работен ден. 3.4. Чийз
                      енд фреш бар ООД не носи отговорност за възможни разлики
                      във визуалното представяне на закупените продукти,
                      зависещи от спецификите в екраните на съответните
                      електронни устройства. Чл. 4 Цени Цените на продуктите
                      показани в сайта са с начислен ДДС. На екрана се
                      изобразяват цената на продукта, размер на отстъпка, ако
                      има такава, размер на допълнителни такси, ако са
                      предвидени такива, такса за доставка и обработка. Цените
                      са деноминирани и платими в Български лева. Цените
                      подлежат на промяна. Чийз енд фреш бар ООД ще информира
                      Регистрираните потребители за всяка промяна в цената преди
                      нова поръчка. Чл. 5 Възможни начини (методи) на плащане
                      5.1. По електронен път с дебитна или кредитна карта
                      Платформата www.cheeseandfresh.com поддържа следните
                      дебитни/кредитни карти: Visa, Visa Electron, V Pay,
                      MasterCard, MasterCard Electronic или Maestro. При избор
                      на този метод за плащане, Потебителят може да плати
                      веднага с кредитна/дебитна карта през сертифицирания
                      виртуален POS терминал на www.cheeseandfresh.com
                      (поддържан от обслужващата ни банка ПроКредит Банк).
                      Отваря се платежна страница на обслужващата ни банка,
                      където Потребителят въвежда данните на своята карта. Не се
                      начислява допълнителна такса при плащане с
                      кредитна/дебитна карта.  Ако Потребителят е регистриран в
                      схемите за автентикация Verified by VISA или MasterCard
                      SecureCode, се отваря автентикационна страница на банката
                      издател, където Потребителят въвежда паролата си за
                      автентикация. При успешна транзакция, на екран се
                      визуализира транзакционна бележка, която Потребителят
                      следва да разпечати и запази.  В момента на online
                      покупката въведените суми ще бъдат автоматично изтеглени
                      от картата на Потребителя при приключване на процеса на
                      плащане. Чийз енд фреш бар ООД ще приеме поръчката и ще
                      изпрати стоката на Клиента, само след като плащането е
                      било успешно одобрено и обработено. Клиентът винаги
                      предплаща продуктите чрез кредитна карта, чрез банков
                      превод или чрез PayPal плащане. 5.2. Чийз енд фреш бар ООД
                      извършва възстановяването само по банкова или картова
                      сметка на клиента. Чийз енд фреш бар ООД има право да
                      отложи възстановяването на плащанията до получаване на
                      стоките обратно или докато не бъдат представени
                      доказателства, че стоката е изпратена обратно, в
                      зависимост от това, кое от двете събития е настъпило
                      по-рано. Чл. 6 Доставка Време за доставка: Чийз енд фреш
                      бар не прави доставки за поръчки под 60 лв. Продукти с
                      обща сума над 60 лв. поръчани в онлайн магазина
                      www.cheeseandfresh.com се доставят със собствен транспорт
                      на адрес посочен от Клиента след 14:30 ч. на уговорения
                      ден. Конкретните дати за доставка зависят от страната на
                      получаване. Поръчки, направени през събота и неделя, както
                      и по време на национални празници за Република България се
                      изпращат в първия последващ ги официален работен ден. Чийз
                      енд фреш бар ООД не носи отговорност, ако доставката се
                      забавя поради местни обичаи и национални празници в
                      страната на получаване. Чл. 7 Транспортни разходи, тарифи,
                      такси 7.1.  Безплатна доставка се извършва до кулинарния
                      магазин на Чийз енд фреш бар ООД, независимо каква е
                      сумата на поръчката. Безплатна доставка се извършва до
                      кулинарния магазин на Чийз енд фреш бар ООД, независимо
                      каква е сумата на поръчката. Доставки до адрес се
                      извършват в района на гр. София на стойност на поръчката
                      минимум 65,00 лв.: Цена на доставка в делнични дни от
                      15:30 ч. до 19:00ч е 9,90 лв. По изключение, след
                      предварителна уговорка и потвърждение от Чийз енд фреш бар
                      ООД, доставката може да бъде изпълнена и в друг времеви
                      диапазон. Безплатна доставка се извършва до адрес на
                      клиента в гр. София при поръчки на стойност минимум
                      100,00лв. Доставки до адрес за населените места София град
                      област - до 20км след Околовръстен път, при поръчки на
                      стойност минимум 100,00 лв.: Цена на доставка в делнични
                      дни от 15:30 ч. до 19:00ч е 16,90 лв. По изключение, след
                      предварителна уговорка и потвърждение от Чийз енд фреш бар
                      ООД, доставката може да бъде изпълнена и в друг времеви
                      диапазон. Безплатна доставка се извършва до адрес на
                      клиента за населените места София град област - до 20км
                      след Околовръстен път, при поръчки на стойност минимум
                      150,00лв.
                    </h5>
                  </DialogContent>
                </Dialog>
              </GridItem>
            </GridContainer>
          )}
        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};
