import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LocalPhone from "@material-ui/icons/LocalPhone";
import LocationOn from "@material-ui/icons/LocationOn";
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
//import GoogleMap from "components/Maps/GoogleMap.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles1 = makeStyles(styles);
const useStyles2 = makeStyles(() => ({
  imgFluid: {
    width: "100%",
    maxWidth: "600px",
    height: "auto",
    maxHeight: "500px",
  },
  imgRounded: {
    borderRadius: "6px !important",
  },
  imgRaised: {
    boxShadow:
      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
}));

export default function ProductSection() {
  const classes = useStyles1();
  const classes2 = useStyles2();
  return (
    <div className={classes.section}>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Button
              color="transparent"
              href="https://www.google.com/maps/place/Cheese+and+Fresh+Bar+%7B%D0%A7%D0%B8%D0%B9%D0%B7+%D0%B5%D0%BD%D0%B4+%D1%84%D1%80%D0%B5%D1%88+%D0%B1%D0%B0%D1%80%7D/@42.6988443,23.3135443,16z/data=!4m5!3m4!1s0x0:0xb43be28af28ffa7e!8m2!3d42.6984027!4d23.3148103"
              target="_blank"
              className={classes.navLink}
            >
              <img
                src={require("assets/img/Map.JPG").default}
                alt="..."
                className={
                  classes2.imgRaised +
                  " " +
                  classes2.imgRounded +
                  " " +
                  classes2.imgFluid
                }
              />
            </Button>
            <br></br>
            <img
              src={require("assets/img/store.jpg").default}
              alt="..."
              className={
                classes2.imgRaised +
                " " +
                classes2.imgRounded +
                " " +
                classes2.imgFluid
              }
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Адрес"
              description="гр. София, пл. Възраждане 8"
              icon={LocationOn}
              iconColor="danger"
              vertical
            />
            <InfoArea
              title="Телефон"
              description="0882240470 / 0882725340"
              icon={LocalPhone}
              iconColor="info"
              vertical
            />
            <InfoArea
              title="И-мейл"
              description="cheeseandfresh@gmail.com"
              icon={Email}
              iconColor="success"
              vertical
            />
            <InfoArea
              title="Работно време"
              description="Лятно работно време: Oт понеделник до петък 08:00-18:00ч. Стандартно работно време: Oт понеделник до петък 07:30-18:30ч. По изключение поръчки се издават и в почивните дни."
              icon={AccessTime}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
